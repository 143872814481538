import {
  formatDateToYYYYMMDD,
  formatDateToHi,
  getDaysOfDateWeek,
} from '@/calendar/utils/index';

export function formatDayWord(numOfDays: number): string {
  return numOfDays > 1 ? 'dni' : 'dzień';
}

export function formatDateToShortDayAndMonth(dateString: Date): string {
  const date = new Date(dateString);

  const options: Intl.DateTimeFormatOptions = {
    day: 'numeric',
    month: 'numeric',
  };

  return new Intl.DateTimeFormat('pl-PL', options).format(date);
}

export function getFirstDayOfMonth(date: Date): Date {
  return new Date(date.getFullYear(), date.getMonth(), 1);
}

export function getLastDayOfMonth(date: Date): Date {
  return new Date(date.getFullYear(), date.getMonth() + 1, 0);
}

export function getHoursMinutesSeconds(
  date: Date,
  { showHours = true, showMinutes = true, showSeconds = true } = {},
): string {
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');
  const timeParts: string[] = [];
  if (showHours) {
    timeParts.push(hours);
  }
  if (showMinutes) {
    timeParts.push(minutes);
  }
  if (showSeconds) {
    timeParts.push(seconds);
  }
  return timeParts.join(':');
}

export function setTodayWithTime(timeString: string) {
  const today = new Date();

  const [hours, minutes] = timeString.split(':').map(Number);

  today.setHours(hours);
  today.setMinutes(minutes);
  today.setSeconds(0);
  today.setMilliseconds(0);

  return today;
}

export function formatDateToDaysMonthsYearsHoursSeconds(date: Date): string {
  const day = date.getDate();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  const hours = date.getHours();
  const minutes = String(date.getMinutes()).padStart(2, '0');

  return `${day}.${month}.${year}, ${hours}:${minutes}`;
}

export function formatDateTime(dateString: string | null): string {
  if (!dateString) return '';

  const date = new Date(dateString);

  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  const hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, '0');

  return `${day}.${month}.${year}, ${hours}:${minutes}`;
}

export function getYesterday(): Date {
  const today = new Date();
  const yesterday = new Date(today.setDate(today.getDate() - 1));

  return yesterday;
}

export { formatDateToYYYYMMDD, formatDateToHi, getDaysOfDateWeek };
